import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CodeDiagrammer from './components/CodeDiagrammer';
import MyAccount from './components/MyAccount';
import About from './components/About';
import Home from './components/Home';
import Header from './components/Header';
import Login from './components/Login';
import Signup from './components/Signup';
import ProtectedRoute from './ProtectedRoute';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="container mt-5">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/codediagrammer"
              element={
                <ProtectedRoute>
                  <CodeDiagrammer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/myaccount"
              element={
                <ProtectedRoute>
                  <MyAccount />
                </ProtectedRoute>
              }
            />
            <Route
              path="/about"
              element={
                <ProtectedRoute>
                  <About />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
