import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h2>About Code Diagrammer</h2>
      <p>
        Code Diagrammer is a tool designed to generate various types of diagrams from code snippets.
        It simplifies the visualization of complex code structures for both business and technical audiences.
      </p>
      <p>
        Use the tool to generate flowcharts, sequence diagrams, class diagrams, and more!
        Learn more about how it works by exploring the Code Diagrammer page.
      </p>
      <a href="/code-diagrammer">Try Code Diagrammer</a>
    </div>
  );
};

export default About;
