import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe(); // Cleanup on component unmount
  }, [auth, navigate]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show loading until auth state is checked
  }

  if (!isAuthenticated) {
    return <div>Redirecting to login...</div>; // Optional fallback while redirecting
  }

  return children; // Render the protected children components
};

export default ProtectedRoute;
