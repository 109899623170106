import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Import navigation
import './Header.css'; 

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Redirect to login after logout
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  return (
    <header className="header">
      <div className="title">
        <a href="/">Code Diagrammer</a>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>

      <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="/">Home</a>
        <a href="/codediagrammer">Code Diagrammer</a>
        <a href="/about">About</a>
        <a href="/myaccount">My Account</a>
        <button className="logout-btn" onClick={handleLogout}>
          Logout
        </button>
      </nav>
    </header>
  );
};

export default Header;