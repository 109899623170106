import { initializeApp } from 'firebase/app'; // Keep this one
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth'; // Import necessary auth functions
import { getFirestore } from 'firebase/firestore'; // Import Firestore

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6uHA25VD1NMzeKgVL5LD3Q_GA2K9urQw",
  authDomain: "code-dia.firebaseapp.com",
  projectId: "code-dia",
  storageBucket: "code-dia.appspot.com",
  messagingSenderId: "913265384789",
  appId: "1:913265384789:web:49822d5524b319334223dc",
  measurementId: "G-84N4SGJ5HH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Set persistence to session-based for authentication
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log("Session-based persistence set successfully.");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

export { app, auth, db };
