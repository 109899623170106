import React, { useState, useEffect } from 'react';
import { getAuth, updateEmail, updatePassword } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';

const MyAccount = () => {
  const [fields, setFields] = useState({
    name: '',
    age: '',
    bio: '',
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  // Fetch user data from Firestore when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const userDoc = doc(db, 'users', user.uid);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setFields({
            name: data.name || '',
            age: data.age || '',
            bio: data.bio || '',
            email: data.email || user.email, // Use Firebase auth email if not in Firestore
            password: '' // Leave password blank for security reasons
          });
        } else {
          setFields({
            name: '',
            age: '',
            bio: '',
            email: user.email,
            password: ''
          });
        }

      } catch (error) {
        console.error('Error fetching document:', error);
        setError('Error loading profile. Please try again later.');
      }

      setLoading(false); // Stop loading once data is fetched
    };

    fetchUserData();
  }, [auth, navigate]);

  // Handle field changes
  const handleFieldChange = (field, value) => {
    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  // Save updates to Firestore and Firebase Auth (for email and password)
  const handleSave = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;

    if (!user) {
      setMessage('You must be logged in to update your profile.');
      return;
    }

    try {
      // Update the email if changed
      if (fields.email !== user.email) {
        await updateEmail(user, fields.email);
      }

      // Update the password if entered
      if (fields.password) {
        await updatePassword(user, fields.password);
      }

      // Save other fields to Firestore
      const userDoc = doc(db, 'users', user.uid);
      await setDoc(userDoc, {
        name: fields.name,
        age: fields.age,
        bio: fields.bio,
        email: fields.email
      }, { merge: true });

      setMessage('Profile updated successfully!');
      setError('');
      setIsEditing(false); // Exit edit mode
    } catch (error) {
      console.error('Error saving document:', error);
      if (error.code === 'auth/requires-recent-login') {
        setError('Please log in again to update your email or password.');
      } else {
        setError('Failed to update profile.');
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-5">
      <h2>My Account</h2>
      {error && <div className="alert alert-danger">{error}</div>}
      {message && <div className="alert alert-success">{message}</div>}

      {isEditing ? (
        <form onSubmit={handleSave}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={fields.name}
              onChange={(e) => handleFieldChange('name', e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="age" className="form-label">Age</label>
            <input
              type="text"
              className="form-control"
              id="age"
              value={fields.age}
              onChange={(e) => handleFieldChange('age', e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="bio" className="form-label">Bio</label>
            <textarea
              className="form-control"
              id="bio"
              value={fields.bio}
              onChange={(e) => handleFieldChange('bio', e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={fields.email}
              onChange={(e) => handleFieldChange('email', e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password (leave blank to keep the same)</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={fields.password}
              onChange={(e) => handleFieldChange('password', e.target.value)}
              placeholder="Enter new password if you wish to change it"
            />
          </div>

          <button type="submit" className="btn btn-primary">Save</button>
        </form>
      ) : (
        <div>
          <p><strong>Name:</strong> {fields.name}</p>
          <p><strong>Age:</strong> {fields.age}</p>
          <p><strong>Bio:</strong> {fields.bio}</p>
          <p><strong>Email:</strong> {fields.email}</p>
          <button className="btn btn-secondary" onClick={handleEdit}>Edit</button>
        </div>
      )}
    </div>
  );
};

export default MyAccount;
