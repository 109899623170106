import React, { useState, useEffect, memo } from 'react';
import { getAuth } from 'firebase/auth';
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import mermaid from 'mermaid';
import Modal from './Modal'; // Import the modal component
import { db } from '../firebase';
import systemMessage from '../systemPrompt'; // Import systemMessage
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure Bootstrap Icons CSS is imported

const CodeDiagrammer = () => {
  const [code, setCode] = useState('');
  const [audience, setAudience] = useState('business');
  const [detailLevel, setDetailLevel] = useState(3);
  const [diagramType, setDiagramType] = useState('flowchart');
  const [output, setOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [runHistory, setRunHistory] = useState([]);
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [activeDiagram, setActiveDiagram] = useState(''); // Store the diagram to render
  const [expandedRuns, setExpandedRuns] = useState({}); // Track expanded run IDs

  const authInstance = getAuth();

  // Initialize Mermaid configuration
  useEffect(() => {
    mermaid.initialize({
      startOnLoad: false, // We'll manually initialize
      theme: 'default', // You can choose other themes like 'dark', 'forest', etc.
    });
  }, []);

  // Fetch and load user's run history on component mount
  useEffect(() => {
    const fetchRunHistory = async () => {
      const user = authInstance.currentUser;
      if (user) {
        const q = query(
          collection(db, 'runHistory'),
          where('userId', '==', user.uid)
        );

        try {
          const querySnapshot = await getDocs(q);
          const history = querySnapshot.docs.map((doc) => ({
            id: doc.id, // Save document ID for deletion
            ...doc.data(),
          }));
          setRunHistory(history);
        } catch (error) {
          console.error('Error fetching run history:', error);
          setErrorMessage('Failed to fetch run history.');
        }
      }
    };

    fetchRunHistory();
  }, [authInstance]);

  const generateDiagram = async () => {
    setLoading(true);
    setErrorMessage('');
    setOutput('');

    const user = authInstance.currentUser;
    if (!user) {
      setErrorMessage('User not authenticated. Please log in.');
      setLoading(false);
      return;
    }

    const userPrompt = `
      Generate a ${diagramType} diagram in valid Mermaid syntax.
      Audience: ${audience}.
      Detail Level: ${detailLevel}.
      Code: ${code}.
      
      Ensure that the Mermaid syntax is correctly formatted for a ${diagramType} diagram without any syntax errors.
      Provide only the Mermaid code without additional explanations or markdown formatting.
    `;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer sk-svcacct-woTpKqLKCC604sgGkYsO9fAQxKPnbwzpMsxCLuW4VIHTAtcsuIY75AzIkdNE5T3BlbkFJZmwWneLkAsWHq1-7gCno1e_N07x_JSwOGTeiHOUK2EMrv5BWFD6Qy1bCIfqrQA`, // Use environment variable
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [
            { role: 'system', content: systemMessage }, // Use imported systemMessage
            { role: 'user', content: userPrompt },
          ],
          max_tokens: 500,
        }),
      });

      const data = await response.json();
      console.log('OpenAI Response:', data); // Log the response for debugging

      if (data.error) {
        throw new Error(`OpenAI API Error: ${data.error.message}`);
      }

      if (!data.choices || !data.choices[0]?.message?.content) {
        throw new Error('No valid diagram received from OpenAI.');
      }

      const mermaidSyntax = data.choices[0].message.content.trim();
      setOutput(mermaidSyntax);

      // Save the run history to Firestore
      const docRef = await addDoc(collection(db, 'runHistory'), {
        userId: user.uid, // Ensure user.uid is available
        code: code || '', // Validate code field
        audience: audience || 'business', // Default to a known value
        detailLevel: detailLevel || 3, // Ensure detail level has a value
        diagramType: diagramType || 'flowchart', // Diagram type
        mermaidSyntax: mermaidSyntax || '', // Mermaid syntax
        createdAt: new Date(), // Use Date() for Firestore timestamp
      });

      setRunHistory((prevHistory) => [
        ...prevHistory,
        {
          id: docRef.id, // Correct ID retrieval for future reference
          code,
          audience,
          detailLevel,
          diagramType,
          mermaidSyntax,
        },
      ]);
    } catch (error) {
      console.error('Error generating diagram:', error);
      setErrorMessage(`Failed to generate diagram: ${error.message}`);
    }

    setLoading(false);
  };

  const restoreRun = (run) => {
    setCode(run.code);
    setAudience(run.audience);
    setDetailLevel(run.detailLevel);
    setDiagramType(run.diagramType);
  };

  const deleteRun = async (runId) => {
    try {
      await deleteDoc(doc(db, 'runHistory', runId));
      setRunHistory(runHistory.filter((run) => run.id !== runId));
      // Also remove from expandedRuns if it's expanded
      setExpandedRuns((prev) => {
        const updated = { ...prev };
        delete updated[runId];
        return updated;
      });
    } catch (error) {
      console.error('Error deleting run:', error);
      setErrorMessage('Failed to delete run history.');
    }
  };

  const toggleHistoryVisibility = () => {
    setIsHistoryVisible(!isHistoryVisible); // Toggle the visibility
  };

  const toggleExpandRun = (runId) => {
    setExpandedRuns((prev) => ({
      ...prev,
      [runId]: !prev[runId],
    }));
  };

  const showDiagram = (run) => {
    const { mermaidSyntax, diagramType } = run;

    let mermaidCode = '';

    // Robust extraction of Mermaid code
    const codeBlockMatch = mermaidSyntax.match(/```mermaid\s*([\s\S]*?)```/i);
    if (codeBlockMatch && codeBlockMatch[1]) {
      mermaidCode = codeBlockMatch[1].trim();
    } else {
      // If no code block, assume the entire content is Mermaid code
      mermaidCode = mermaidSyntax.trim();
    }

    // Log the extracted Mermaid code for debugging
    console.log('Extracted Mermaid Code:', mermaidCode);

    // Validate Mermaid code
    try {
      mermaid.parse(mermaidCode);
    } catch (error) {
      console.error('Mermaid syntax error:', error);
      setErrorMessage(`Mermaid syntax error: ${error.str}`);
      return;
    }

    setActiveDiagram(mermaidCode); // Set the active diagram syntax
    setIsModalOpen(true); // Open the modal
  };

  // Initialize Mermaid whenever activeDiagram changes
  useEffect(() => {
    if (activeDiagram) {
      try {
        mermaid.init(undefined, '#mermaid-diagram'); // Trigger mermaid rendering inside the modal
      } catch (error) {
        console.error('Mermaid rendering error:', error);
        setErrorMessage(`Mermaid rendering error: ${error.message}`);
      }
    }
  }, [activeDiagram]);

  return (
    <div className="code-diagrammer-container">
      <div className="form-container shadow p-4">
        <h1 className="text-center mb-4">Generate a Code Diagram</h1>

        <div className="form-group">
          <label>Enter your code:</label>
          <textarea
            className="form-control"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter your code here..."
            rows={6}
          />
        </div>

        <div className="form-group">
          <label>Select Audience:</label>
          <select
            className="form-control"
            value={audience}
            onChange={(e) => setAudience(e.target.value)}
          >
            <option value="business">Business</option>
            <option value="technical">Technical</option>
          </select>
        </div>

        <div className="form-group">
          <label>Select Level of Detail:</label>
          <input
            type="range"
            className="form-range"
            value={detailLevel}
            min="1"
            max="5"
            onChange={(e) => setDetailLevel(e.target.value)}
          />
          <div className="text-center">Detail Level: {detailLevel}</div>
        </div>

        <div className="form-group">
          <label>Diagram Type:</label>
          <select
            className="form-control"
            value={diagramType}
            onChange={(e) => setDiagramType(e.target.value)}
          >
            <option value="flowchart">Flowchart</option>
            <option value="sequence">Sequence Diagram</option>
            <option value="class">Class Diagram</option>
            <option value="state">State Diagram</option>
          </select>
        </div>

        <button
          className="btn btn-primary btn-block mt-3"
          onClick={generateDiagram}
          disabled={loading}
        >
          {loading ? 'Generating...' : 'Generate Diagram'}
        </button>

        {errorMessage && (
          <div className="alert alert-danger mt-3">{errorMessage}</div>
        )}

        {output && (
          <div className="output-box mt-4 p-3 border rounded">
            <h2>Diagram Output</h2>
            <pre>{output}</pre>
          </div>
        )}

        {/* Collapsible Run History */}
        <div className="run-history-toggle mt-4">
          <button
            className="btn btn-info"
            onClick={toggleHistoryVisibility}
          >
            {isHistoryVisible ? 'Hide Run History' : 'Show Run History'}
          </button>
        </div>

        {isHistoryVisible && runHistory.length > 0 && (
          <div className="run-history mt-4">
            <h3>Run History</h3>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Audience</th>
                  <th>Detail Level</th>
                  <th>Diagram Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {runHistory.map((run) => (
                  <React.Fragment key={run.id}>
                    <tr>
                      <td>{run.id}</td>
                      <td>{run.audience}</td>
                      <td>{run.detailLevel}</td>
                      <td>{run.diagramType}</td>
                      <td>
                        <div className="btn-group" role="group" aria-label="Run Actions">
                          {/* Restore Button */}
                          <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={() => restoreRun(run)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Restore Run"
                          >
                            <i className="bi bi-arrow-counterclockwise"></i>
                          </button>

                          {/* Delete Button */}
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => deleteRun(run.id)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete Run"
                          >
                            <i className="bi bi-trash"></i>
                          </button>

                          {/* Show Diagram Button */}
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={() => showDiagram(run)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Show Diagram"
                          >
                            <i className="bi bi-eye"></i>
                          </button>

                          {/* Expand/Collapse Button */}
                          <button
                            type="button"
                            className="btn btn-light btn-sm"
                            onClick={() => toggleExpandRun(run.id)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={expandedRuns[run.id] ? 'Collapse Details' : 'Expand Details'}
                          >
                            <i className={`bi ${expandedRuns[run.id] ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    {expandedRuns[run.id] && (
                      <tr>
                        <td colSpan="5">
                          <div className="expanded-details p-3 bg-light rounded">
                            <h5>Code:</h5>
                            <pre>{run.code}</pre>
                            <h5>Mermaid Syntax:</h5>
                            <pre>{run.mermaidSyntax}</pre>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Mermaid Diagram Modal */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="mermaid" id="mermaid-diagram">
            {activeDiagram}
          </div>
        </Modal>
      </div>
    </div>
  );
};

// Memoized RunRow component for performance optimization
const RunRow = memo(({ run, restoreRun, deleteRun, showDiagram, toggleExpandRun, isExpanded }) => (
  <React.Fragment>
    <tr>
      <td>{run.id}</td>
      <td>{run.audience}</td>
      <td>{run.detailLevel}</td>
      <td>{run.diagramType}</td>
      <td>
        <div className="btn-group" role="group" aria-label="Run Actions">
          {/* Restore Button */}
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={() => restoreRun(run)}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Restore Run"
          >
            <i className="bi bi-arrow-counterclockwise"></i>
          </button>

          {/* Delete Button */}
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => deleteRun(run.id)}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Delete Run"
          >
            <i className="bi bi-trash"></i>
          </button>

          {/* Show Diagram Button */}
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => showDiagram(run)}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Show Diagram"
          >
            <i className="bi bi-eye"></i>
          </button>

          {/* Expand/Collapse Button */}
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={() => toggleExpandRun(run.id)}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={isExpanded ? 'Collapse Details' : 'Expand Details'}
          >
            <i className={`bi ${isExpanded ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
          </button>
        </div>
      </td>
    </tr>
    {isExpanded && (
      <tr>
        <td colSpan="5">
          <div className="expanded-details p-3 bg-light rounded">
            <h5>Code:</h5>
            <pre>{run.code}</pre>
            <h5>Mermaid Syntax:</h5>
            <pre>{run.mermaidSyntax}</pre>
          </div>
        </td>
      </tr>
    )}
  </React.Fragment>
));

export default CodeDiagrammer;
