import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Code Diagrammer</h1>
      <p>
        Start by navigating to the Code Diagrammer page to generate your diagrams, 
        or explore the My Account page to manage your profile.
      </p>
      <a href="/codediagrammer">Go to Code Diagrammer</a>
    </div>
  );
};

export default Home;
